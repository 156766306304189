import React, {useContext, useEffect, useState} from 'react';
import {fetchFavoritesMatches, fetchLiteMatches, fetchLiveScoresHome} from "../../api";
import './LiveScores.css'
import '../../App.css'
import LiveScoresLeagueAndMatchesTable from "./LiveScoresLeagueAndMatchesTable";
import livePng from "../../assets/icons/live-icon.png";
import dayjs from "dayjs";
import {AppContext} from "../../context/AppContext";
import LeagueAndMatchesTable from "../stats/LeagueAndMatchesTable";
import {DateTimePicker, DesktopDateTimePicker, MobileDateTimePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import arrowIcon from '../../assets/icons/right-icon.png'
import DocumentMeta from 'react-document-meta';
import {liveScoresMeta} from "../../SEO/SeoMetaTags";

function LiveScores(props) {
    const {favLiveScoresMatches, lang} = useContext(AppContext);

    const [liveScoresData, setLiveScoresData] = useState(null);
    const [liveData, setLiveData] = useState(null);
    const [live, setLive] = useState(false);
    const [liveInstantMatches, setLiveInstantMatches] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().substr(0, 10));
    const [fetchFavMatches, setFetchFavMatches] = useState([]);
    const [week, setWeek] = useState([
        {day: dayjs(currentDate).add(-2, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(-1, 'days').format('YYYY-MM-DD')},
        {day: new Date().toISOString().substr(0, 10),},
        {day: dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(2, 'days').format('YYYY-MM-DD')},
    ]);

    /*LIVE SCORES CODES*/
    useEffect(() => {
        const fetchLiveHome = async () => {
            try {
                const liveScoresHome = await fetchLiveScoresHome(currentDate, lang);
                const liveScore = liveScoresHome.data.data;
                setLiveScoresData(liveScore);
                setLiveData(liveScore);
            } catch (error) {
                console.log(error)
            }
        }
        fetchLiveHome();
    }, [currentDate, lang])

    const fixtureMatches = [];
    const liveScoresLeagueIndex = {};
    if (liveScoresData) {
        liveScoresData.forEach((leagues, index) => {
            const {leagueId, league, areaId, area, seasonId} = leagues;
            liveScoresLeagueIndex[leagueId] = index;
            leagues.matches.forEach(match => {
                fixtureMatches.push({
                    leagueDetail: {leagueId, league, areaId, area, seasonId},
                    match
                })
            })
        })
    }

    let filteredInstantLiveMatches = [];
    if (liveInstantMatches) {
        /*const liveInstant = liveInstantMatches.filter(item => item.status === 'Playing');*/
        filteredInstantLiveMatches = fixtureMatches.map(matches => {
            const findLiveInstant = liveInstantMatches.find(match => match.id === matches.match.id)
            return {
                ...matches,
                findLiveInstant
            }
        })
    }

    const matchesByLeagues = {};
    const leagueDetails = {};
    filteredInstantLiveMatches.forEach(liveMatch => {
        const {leagueDetail, match, findLiveInstant} = liveMatch;
        const asd = {...match, ...findLiveInstant};

        leagueDetails[leagueDetail.leagueId] = leagueDetail;

        const matches = matchesByLeagues[leagueDetail.leagueId] || [];
        matchesByLeagues[leagueDetail.leagueId] = [...matches, asd];
    })

    const sections = Object.entries(matchesByLeagues).map(entry => {
        const [leagueId, matches] = entry;
        return {
            title: leagueDetails[leagueId],
            data: matches
        }
    }).sort(
        (a, b) => liveScoresLeagueIndex[a.title.leagueId] - liveScoresLeagueIndex[b.title.leagueId],
    );

    /*FAV MATCHES CODES*/
    useEffect(() => {
        if (favLiveScoresMatches.length > 0) {
            const matchesId = favLiveScoresMatches.map(item => item.match.id);

            fetchFavoritesMatches(matchesId).then(data => {
                const fetchMatches = data.data.data;
                setFetchFavMatches(fetchMatches);
            });

            const interval = setInterval(() => {
                const favMatches = async () => {
                    try {
                        const fixturesLite = await fetchFavoritesMatches(matchesId);
                        const fetchMatches = fixturesLite.data.data;
                        setFetchFavMatches(fetchMatches);
                    } catch (error) {
                        console.log(error)
                    }
                }
                favMatches();
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [favLiveScoresMatches]);

    const favMatchesByLeagues = {};
    const favLeagueDetails = {};
    favLiveScoresMatches.forEach(favMatch => {
        const {leagueDetail, match} = favMatch;
        favLeagueDetails[leagueDetail.leagueId] = leagueDetail;

        const fetchFavMatch = fetchFavMatches.find(item => item.id === match.id);

        const matches = favMatchesByLeagues[leagueDetail.leagueId] || [];
        if (match.status !== 'Played') {
            favMatchesByLeagues[leagueDetail.leagueId] = [
                ...matches,
                {...match, ...fetchFavMatch},
            ];
        }
    });

    const favSections = Object.entries(favMatchesByLeagues).map(entry => {
        const [leagueId, matches] = entry;
        return {
            title: favLeagueDetails[leagueId],
            data: matches,
        };
    });

    /*LIVE MATCHES CODES*/
    useEffect(() => {
        const today = new Date().toISOString().substr(0, 10);
        if (currentDate === today) {
            const instantLiveMatches = async () => {
                try {
                    const liveScores = await fetchLiteMatches();
                    const instantMatches = liveScores.data.data;
                    setLiveInstantMatches(instantMatches);
                } catch (error) {
                    console.log(error)
                }
            }

            instantLiveMatches();

            const interval = setInterval(() => {
                const instantLiveMatches = async () => {
                    try {
                        const liveScores = await fetchLiteMatches();
                        const instantMatches = liveScores.data.data;
                        setLiveInstantMatches(instantMatches);
                    } catch (error) {
                        console.log(error)
                    }
                }
                instantLiveMatches();
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [currentDate]);

    const liveMatches = [];
    const liveLeaguesIndex = {};
    if (liveData) {
        liveData.forEach((leagues, index) => {
            const {leagueId, league, areaId, area} = leagues;
            liveLeaguesIndex[leagueId] = index;
            const matchesWithStatusPlaying = leagues.matches.filter(match => {
                return liveInstantMatches?.some(instantMatch => {
                    return match.id === instantMatch.id && instantMatch.status === 'Playing'
                });
            });
            matchesWithStatusPlaying.forEach(match => {
                liveMatches.push({
                    leagueDetail: {leagueId, league, areaId, area},
                    match,
                });
            })
        });
    }

    let filteredLiveMatches = [];
    if (liveInstantMatches) {
        const liveInstant = liveInstantMatches.filter(
            item => item.status === 'Playing',
        );
        filteredLiveMatches = liveMatches.map(matches => {
            const findLiveInstant = liveInstant.find(
                match => match.id === matches.match.id,
            );
            return {
                ...matches,
                findLiveInstant,
            };
        });
    }

    const liveMatchesByLeagues = {};
    const liveLeagueDetails = {};
    filteredLiveMatches.forEach(liveMatch => {
        const {leagueDetail, match, findLiveInstant} = liveMatch;
        const matchAndLiveInstant = {...match, ...findLiveInstant};

        liveLeagueDetails[leagueDetail.leagueId] = leagueDetail;

        const matches = liveMatchesByLeagues[leagueDetail.leagueId] || [];
        liveMatchesByLeagues[leagueDetail.leagueId] = [...matches, matchAndLiveInstant];
    });

    const liveSections = Object.entries(liveMatchesByLeagues).map(entry => {
        const [leagueId, matches] = entry;
        return {
            title: liveLeagueDetails[leagueId],
            data: matches,
        };
    }).sort(
        (a, b) => liveLeaguesIndex[a.title.leagueId] - liveLeaguesIndex[b.title.leagueId],
    );


    /*********************************************/
    const liveMatchButton = (live) => {
        setLive(!live)
    }

    //Week Days Format
    const format = (day) => {
        return dayjs(day).format('dddd')
    }

    const format2 = (day) => {
        return dayjs(day).format('DD')
    }

    //Week Days Format
    const formatMobile = (day) => {
        return dayjs(day).format('ddd')
    }

    const format2Mobile = (day) => {
        return dayjs(day).format('DD')
    }

    const clickDays = async (day) => {
        await setCurrentDate(day)
    }

    const beforeDay =  () => {
        const beforeDay = dayjs(currentDate).add(-1, 'days').format('YYYY-MM-DD');
        setCurrentDate(beforeDay);
    }
    const nextDay =  () => {
        const beforeDay = dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD');
        setCurrentDate(beforeDay);
    }

    const activeDayStyle = (day) => {
        if (currentDate === day.day) {
            return 'live-scores-active-week-day'
        }
    }

    const daysFormatFunc = (date) => {
        const currentDate = new Date().toISOString().substr(0, 10);
        const yesterday = dayjs(currentDate).add(-1, 'days').format('YYYY-MM-DD')
        const tomorrow = dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD');
        if (date.day === currentDate) {
            return (
                <div>

                    {
                        lang === 'tr' ?
                            <span className='live-scores-date-day-name'>Bugün</span>
                            :
                            <span className='live-scores-date-day-name'>Today</span>
                    }
                </div>
            )
        } else if (date.day === yesterday) {
            return (
                <div>
                    {
                        lang === 'tr' ?
                            <span className='live-scores-date-day-name'>Dün</span>
                            :
                            <span className='live-scores-date-day-name'>Yesterday</span>
                    }
                </div>
            )
        } else if (date.day === tomorrow) {
            return (
                <div>
                    {
                        lang === 'tr' ?
                            <span className='live-scores-date-day-name'>Yarın</span>
                            :
                            <span className='live-scores-date-day-name'>Tomorrow</span>
                    }
                </div>
            )
        } else {
            return (
                <div>
                    <span>{format2(date.day)}</span>
                    <span className='live-scores-date-day-name live-scores-date-margin-left'>{format(date.day)}</span>
                </div>
            )
        }
    }

    return (
        <DocumentMeta {...liveScoresMeta[lang]}>
            <div className="const-width live-scores-section">
                <div className="live-scores-general-container">
                    {/*FOR WEB*/}
                    <div className="live-scores-top-header">
                        <div className="live-scores-live-status-container">
                            <div className="live-container" onClick={() => liveMatchButton(live)}>
                                {
                                    live ?
                                        <div className="live-scores-live-match">
                                            <img className="live-icon" src={livePng} alt=""/>
                                            {
                                                lang === 'tr' ?
                                                    <span className="live-text">Canlı</span>
                                                    :
                                                    <span className="live-text">Live</span>
                                            }
                                            <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                        </div>
                                        :
                                        <div className="not-live">
                                            <img className="not-live-icon" src={livePng} alt=""/>
                                            {
                                                lang === 'tr' ?
                                                    <span className="not-live-text">Canlı</span>
                                                    :
                                                    <span className="not-live-text">Live</span>
                                            }
                                            <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                        </div>
                                }
                            </div>
                        </div>

                        <div className='live-scores-week-days-and-dtp-section'>
                            <div className="live-scores-week-days-section">
                                {
                                    week.map((day) => {
                                        return (
                                            <div key={day.day} className="live-scores-week-days-container">
                                                <div onClick={() => clickDays(day.day)}
                                                     className={`${'live-scores-dates'} ${activeDayStyle(day)}`}>
                                                    {daysFormatFunc(day)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className='live-scores-date-time-picker'>
                                <div onClick={beforeDay} className='date-arrow-container reverse-right-arrow-icon'>
                                    <img className='arrow-img' src={arrowIcon} alt="Before"/>
                                </div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDateTimePicker
                                        sx={{
                                            width: '130px',
                                        }}
                                        views={["year", "month", "day"]}
                                        format="DD-MM-ddd"
                                        onChange={(value) => clickDays(dayjs(value).format('YYYY-MM-DD'))}
                                        value={dayjs(currentDate)}
                                        defaultValue={dayjs(currentDate)}/>
                                </LocalizationProvider>
                                <div onClick={nextDay} className='date-arrow-container'>
                                    <img className='arrow-img' src={arrowIcon} alt="Next"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*FOR MOBILE*/}
                    <div className="live-scores-top-header-mobile const-width-content">
                        <div className={'live-and-weekdays-container-mobile'}>
                            <div className="live-status-container">
                                <div className="live-container" onClick={() => liveMatchButton(live)}>
                                    {
                                        live ?
                                            <div className="live-match">
                                                <img className="live-icon" src={livePng} alt=""/>
                                                {
                                                    lang === 'tr' ?
                                                        <span className="live-text">Canlı</span>
                                                        :
                                                        <span className="live-text">Live</span>
                                                }
                                                <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                            </div>
                                            :
                                            <div className="not-live">
                                                <img className="not-live-icon" src={livePng} alt=""/>
                                                {
                                                    lang === 'tr' ?
                                                        <span className="not-live-text">Canlı</span>
                                                        :
                                                        <span className="not-live-text">Live</span>
                                                }
                                                <span className={'live-match-count'}>{filteredLiveMatches.length}</span>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="week-days-section">
                                {
                                    week.map((day) => {
                                        return (
                                            <div key={day.day} className="week-days-container">
                                                <div onClick={() => clickDays(day.day)}
                                                     className={`${'dates'} ${activeDayStyle(day)}`}>
                                                    <span>{formatMobile(day.day)}</span>
                                                    <span>{format2Mobile(day.day)}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {/*FAV SECTIONS*/}
                    {
                        favSections && favLiveScoresMatches.length > 0 && favSections.map((league) => {
                            const favMatches = favMatchesByLeagues[league.title.leagueId] || [];
                            return (
                                favMatches.length === 0 ? null :
                                    <LiveScoresLeagueAndMatchesTable key={league.title.leagueId} favMatches={favMatches}
                                                                     league={league} matches={favMatches}/>
                            )
                        })
                    }
                    {/*LIVE SCORES SECTIONS*/}
                    {
                        sections && !live && sections.map((league) => {
                            const matches = matchesByLeagues[league.title.leagueId] || [];
                            return (
                                matches.length === 0 ? null :
                                    <LiveScoresLeagueAndMatchesTable key={league.title.leagueId} league={league}
                                                                     matches={matches}/>
                            )
                        })
                    }
                    {/*LIVE SECTIONS*/}
                    {
                        liveSections.length !== 0 && live && liveSections.map((league) => {
                            const liveMatches = liveMatchesByLeagues[league.title.leagueId] || [];
                            return (
                                <div>
                                    {
                                        liveMatches.length === 0 ? null :
                                            <LiveScoresLeagueAndMatchesTable key={league.title.leagueId} league={league}
                                                                             matches={liveMatches}/>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </DocumentMeta>
    );
}

export default LiveScores;
