import React, {useContext, useEffect, useState} from 'react';
import {fetchLineups} from '../../../api';
import LineupCard from './LineupCard';
import {AppContext} from '../../../context/AppContext';
import './Lineups.css';

function Lineups(props) {
    const {lang} = useContext(AppContext);
    const {matchTeamsDetail, matchId, i18String} = props;

    const [lineupsData, setLineupsData] = useState(null);

    useEffect(() => {
        fetchLineups(matchId).then(data => {
            setLineupsData(data.data.data);
        });
    }, [matchId]);

    const i18n = {
        startingLineups: 'Starting Lineups',
        Substitutes: 'Substitutes',
        Lineups: 'Lineups',
    };

    return (
        <div className='lineupsGeneralSection'>
            {/* LINEUPS SECTION */}
            <div className='lineupsTitleTextContainer'>
        <span className='lineupsTitleText'>
          {i18n.startingLineups}
        </span>
            </div>

            <div className='lineupsAndBenchTableContainer'>
                {/* HOME LINEUP */}
                <LineupCard
                    data={lineupsData?.home?.lineup}
                    teamName={matchTeamsDetail?.home}
                    teamId={matchTeamsDetail?.homeId}
                />

                {/* AWAY LINEUP */}
                <LineupCard
                    data={lineupsData?.away?.lineup}
                    teamName={matchTeamsDetail?.away}
                    teamId={matchTeamsDetail?.awayId}
                />
            </div>

            {/* BENCH SECTION */}
            <div className='lineupsTitleTextContainer'>
                <span className='lineupsTitleText'>
                  {i18n.Substitutes}
                </span>
            </div>

            <div className='lineupsAndBenchTableContainer'>
                {/* HOME BENCH */}
                <LineupCard
                    data={lineupsData?.home?.bench}
                    teamName={matchTeamsDetail?.home}
                    teamId={matchTeamsDetail?.homeId}
                />

                {/* AWAY BENCH */}
                <LineupCard
                    data={lineupsData?.away?.bench}
                    teamName={matchTeamsDetail?.away}
                    teamId={matchTeamsDetail?.awayId}
                />
            </div>
        </div>
    );
}

export default Lineups;
