import React, {useContext} from 'react';
import padLock from '../../../assets/icons/padlock.png'
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../context/AppContext";

function MatchDetailsStatsEvent(props) {
    const {isPro} = useContext(AppContext);

    const {matchDetailTeamData} = props;

    const navigate = useNavigate();
    const toJoinPremium = () => {
        navigate('/join-premium')
    }

    return (
        <div className={'stats-section'}>
            {
                matchDetailTeamData && matchDetailTeamData.map(match => {
                    return (
                        <div key={match.name}>
                            <div className='match-detail-stats-title-container'>
                                <span className='match-detail-stats-title'>{match.name}</span>
                            </div>
                            <div className={'stats-general-container'}>
                                {
                                    match.stats.map(stats => {
                                        return (
                                            <div className={'match-detail-stats-container'}>
                                                {
                                                    isPro ?
                                                        <div className={'match-detail-stats'}>{stats.hhome}</div>
                                                        :
                                                        <img onClick={() => toJoinPremium()}  className='pad-lock-icon' src={padLock} alt=""/>
                                                }
                                                <div className={'match-detail-stats-name'}>{stats.name}</div>

                                                {
                                                    isPro ?
                                                        <div className={'match-detail-stats'}>{stats.aaway}</div>
                                                        :
                                                        <img onClick={() => toJoinPremium()} className='pad-lock-icon' src={padLock} alt=""/>
                                                }

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default MatchDetailsStatsEvent;
